export default {
  alt: 'Seleccione idioma',
  en: {
    alt: 'Inglés',
    title: 'Inglés',
  },
  es: {
    alt: 'Español',
    title: 'Español',
  },
  close: {
    alt: 'Cerca',
  },
}
