<template>
  <div class="form__input">
    <h5
      class="info_title form__input__title info_title"
      :class="titleSmall ? 'info_title--small' : 'info_title'"
    >
      {{ title }}:
    </h5>
    <div class="form__input__multiple_field_wrapper">
      <div
        v-for="option in options"
        :key="option.value"
        class="form__input_checkbox"
      >
        <input
          class="form__input__field_radio"
          type="radio"
          :value="option.value"
          :checked="modelValue === option.value"
          @input="updateValue(option.value)"
        >
        <label class="form__input_checkbox__text">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-radio',
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    titleSmall: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
