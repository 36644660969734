// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-loader/leaflet-loader'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-loader/leaflet-loader.css'
import { dashUrl } from '@/config'
import L from 'leaflet'
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'

const controlLoader = L.control.loader()
const types_val = reactive({})

function checkDelivery(clientId, geo) {
  return fetch(`${dashUrl}/ajax/PullCommon.php?action=checkDelivery`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      typeId: 9,
      clientId,
      geo,
    }),
  })
    .then(result => result.json())
    .then(({ types }) => {
      Object.assign(types_val, types)
    })
}

export function useTypes() {
  const { orderData } = useStore().getters
  const orderGeo = { lat: orderData.recipient.address.lat, lng: orderData.recipient.address.lng }

  checkDelivery(orderData.client.uid, orderGeo)

  return { types: types_val }
}

export default function useAddress(computedAddress) {
  const orderGeo = computed(() => ({ lat: computedAddress.value.lat, lng: computedAddress.value.lng }))
  const { orderData } = useStore().getters

  watch(() => [computedAddress.value.lat, computedAddress.value.lng], v => {
    if (!v[0] || !v[1]) {
      controlLoader.show()
      return
    }
    controlLoader.show()
    computedAddress.value.delivery = undefined
    checkDelivery(orderData.client.uid, orderGeo.value)
      .finally(() => {
        controlLoader.hide()
      })
  })

  return { controlLoader, orderGeo }
}
