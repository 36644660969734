<template>
  <div>
    <h2 class="section__title">{{ text }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ready-for-delivery',
  computed: {
    ...mapGetters(['orderData', 'dateToDeliver', 'timeToDeliver']),
    text() {
      let text = this.$t('ready-for-delivery')
      if (this.dateToDeliver) {
        text += this.dateToDeliver
      }

      if (this.timeToDeliver) {
        text += ` ${this.timeToDeliver}`
      }

      return text
    },
  },
}
</script>
