export default class NotationHelper {
  static x02 = '01'

  static x08 = `${NotationHelper.x02}234567`

  static x10 = `${NotationHelper.x08}89`

  static x16 = `${NotationHelper.x10}abcdef`

  static x32 = `${NotationHelper.x16}ghijklmnopqrstuv`

  static x36 = `${NotationHelper.x32}wxyz`

  static x62 = `${NotationHelper.x36}ABCDEFGHIJKLMNOPQRSTUVWXYZ`

  static x64 = `${NotationHelper.x62}!*`

  static x71 = `${NotationHelper.x64}().-~'_`

  static x94 = `${NotationHelper.x71}\\>=<@#$%^&\`{,}[]+|?/";:`

  static sys = {
    x02: NotationHelper.x02,
    x08: NotationHelper.x08,
    x10: NotationHelper.x10,
    x16: NotationHelper.x16,
    x32: NotationHelper.x32,
    x36: NotationHelper.x36,
    x62: NotationHelper.x62,
    x64: NotationHelper.x64,
    x71: NotationHelper.x71,
    x94: NotationHelper.x94,
  }

  static def = 'x62'

  static strToDec(s, si = NotationHelper.def) {
    const sys = NotationHelper.sys[si].split('')
    const r = sys.length
    let j = -1
    let result = 0
    for (let i = s.length - 1; i >= 0; i--) {
      j++
      const index = sys.indexOf(s[j])
      result += (index + 1) * r ** i
    }
    return result - 1
  }
}
