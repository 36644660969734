export default {
  card: {
    title: 'Pagar con tarjeta',
    inputs: {
      number: {
        tite: 'Número de tarjeta',
        placeholder: 'Introduzca el número de tarjeta',
      },
    },
  },
  'driver-tips': {
    title: 'Gracias por<br><span>apoyando a los conductores!</span>',
    description: 'El 100% de tu propina va directamente a',
    'confirmation-modal': {
      title: 'Por favor confirme:',
      description: '¿Estás seguro de que quieres enviar un consejo de',
      error: 'El monto de la propina debe estar entre $2 y $40.',
      actions: {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
    },
  },
  'tips-amount': {
    other: 'Otro',
  },
  'tips-component': {
    thanks: 'Thank you! Your tip has been received and will surely make the driver\'s day brighter.',
    more: 'Envía más consejos',
  },
  'tips-old': {
    title: 'Gracias por<br><span>apoyando a los conductores!</span>',
    description: 'El 100% de tu propina va directamente a',
    btn: 'Tarjeta de pago',
  },
}
