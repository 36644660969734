export default {
  buttons: {
    reschedule: 'Reschedule',
    'reschedule-change-address': 'Reschedule/Change address',
    'add-time': 'Add time',
  },
  'pickup-time-min-title': 'Pickup Time min',
  'pickup-time-max-title': 'Pickup Time max',
  'pickup-date': 'Pick a delivery date',
  'change-address': {
    title: 'You have changed your address',
    description: 'Please allow us some time to process your order, and we will provide you with the tracking link.',
    error: 'Sorry, wo do not delivery to theselected address, Please enter another address',
  },
}
