export default {
  accepted: {
    description: 'Signature accepted. Thank you for using our delivery service!',
  },
  info: 'You have a delivery from',
  title: 'Please verify your information:',
  fields: {
    name: 'Recipient Name:',
    phone: 'Recipient Phone:',
    address: 'Recipient Address:',
    'rx-number': 'Rx Number:',
  },
  'action-btn': 'Submit',
}
