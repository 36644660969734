<template>
  <div v-if="orderData.status_slug !== 'driver-out'" class="order-status-block">
    <div v-if="attempts" class="attempts-count">
      <span>{{ $t('main-page.delivering.not-confirmed.progress.attempts') }} {{ attempts + 1 }}</span>
    </div>
    <ul class="order_status">
      <li class="order_status__item" :class="{ 'active': orderStatus >= 1 }">
        <i class="icon order_status__icon">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.547 3.719H3.52a1.926 1.926 0 00-1.926 1.926v5.246a1.86 1.86 0 001.86 1.859h.265v1.318a1.338 1.338 0 001.339 1.338h6.885a1.338 1.338 0 001.338-1.338V12.75h.266a1.86 1.86 0 001.86-1.86V5.579a1.86 1.86 0 00-1.86-1.86zm-1.328 10.349a.277.277 0 01-.276.276H5.058a.278.278 0 01-.277-.276V8.776a.278.278 0 01.277-.276h6.885a.277.277 0 01.276.276v5.292zm.863-7.165a.797.797 0 11-.17-1.585.797.797 0 01.17 1.585zM11.422 1.594H5.578a1.863 1.863 0 00-1.84 1.593h9.524a1.863 1.863 0 00-1.84-1.593z"/></svg>
        </i>
        <div class="order_status__title" v-html="$t('main-page.delivering.not-confirmed.progress.created')"></div>
      </li>
      <li class="order_status__item" :class="{ 'active': orderStatus >= 2 }">
        <i class="icon order_status__icon">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M12.12 3.553c1.473.342 2.037 1.204 2.744 2.39h-2.745v-2.39zm-8.614 2.49c.288 0 .498.14.498.465a.498.498 0 01-.497.498H.498a.498.498 0 000 .996H4.98a.498.498 0 11.002.996H.498a.498.498 0 100 .996h1.096v1.494c0 .275.223.498.498.498h1.001a1.91 1.91 0 003.742 0h5.057a1.91 1.91 0 003.742 0h.868a.498.498 0 00.498-.498V8.5c0-1.461-1.541-1.558-1.543-1.56h-3.836a.498.498 0 01-.498-.499V3.453H2.092a.498.498 0 00-.498.498v1.096H.996a.498.498 0 100 .996h2.51zm10.902 4.916a.913.913 0 11-1.291 1.291.913.913 0 011.291-1.291zm-8.798 0a.913.913 0 11-1.292 1.291.913.913 0 011.292-1.291z"/></svg>
        </i>
        <div class="order_status__title">{{ $t('main-page.delivering.not-confirmed.transit') }}</div>
      </li>
      <li class="order_status__item" :class="{ 'active': orderStatus >= 3 }">
        <i class="icon order_status__icon">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.864 7.331a.53.53 0 00-.033-.102L13.577 4.3c-.29-.676-.993-1.114-1.793-1.114H5.216c-.8 0-1.504.438-1.793 1.114L2.168 7.229a.522.522 0 00-.043.208v5.844a.531.531 0 00.531.531H3.72a.531.531 0 00.531-.53v-.532h8.5v.531a.531.531 0 00.531.531h1.063a.531.531 0 00.531-.53V7.436a.534.534 0 00-.01-.106zM4.781 10.625a1.062 1.062 0 110-2.125 1.062 1.062 0 010 2.125zm7.438 0a1.062 1.062 0 110-2.124 1.062 1.062 0 010 2.124zM3.462 6.906l.937-2.186c.12-.28.449-.47.818-.47h6.566c.369 0 .698.19.818.47l.937 2.186H3.462z"/></svg>
        </i>
        <div class="order_status__title" :v-html="$t('main-page.delivering.not-confirmed.out-for-delivery')">Out for <br> Delivery</div>
      </li>
      <li class="order_status__item" :class="{ 'active': orderStatus >= 4 }">
        <i class="icon order_status__icon">
          <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.5 1C4.365 1 1 4.365 1 8.5S4.365 16 8.5 16 16 12.635 16 8.5 12.635 1 8.5 1zm3.903 4.986l-4.846 5.77a.576.576 0 01-.432.205h-.01a.578.578 0 01-.429-.19L4.61 9.463a.578.578 0 11.857-.772L7.1 10.506l4.421-5.261a.577.577 0 01.883.741z"/></svg>
        </i>
        <div class="order_status__title">{{ $t('main-page.delivering.not-confirmed.delivered') }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STATUS_CONDITIONS = {
  1: ['ready-to-print', 'ready-for-pickup'],
  2: ['pickup-occurred', 'ready-for-delivery', 'route-optimized'],
  3: ['driver-out'],
  4: ['delivered', 'delivery-attempted'],
}

export default {
  name: 'deliver-progress-line',
  props: {
    forceStatus: {
      type: Number,
      require: false,
      default: null,
    },
    attempts: {
      type: Number,
      require: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['orderData']),
    orderStatus() {
      if (this.forceStatus) {
        return this.forceStatus
      }

      const statusSlug = this.orderData.status_slug

      if (!statusSlug) {
        return 0
      }

      for (const [statusNumber, slugs] of Object.entries(STATUS_CONDITIONS)) {
        if (slugs.includes(statusSlug)) {
          return parseInt(statusNumber, 10)
        }
      }

      return 0
    },
  },
}
</script>

<style lang="scss" scoped>
.attempts-count {
  background-color: #f7f8fb;
  padding-top: 5px;
  padding-left: 5px;

  span {
    color: #21979E;
    font-weight: 600;
    font-size: 13px;
  }
}
</style>
