export default {
  title: 'Ahora puede cerrar esta página. Si tiene alguna pregunta, no dude en llamarnos al',
  'call-rating': 'Calificación de llamadas',
  comment: {
    title: 'Por favor deja tus comentarios:',
    placeholder: 'Escribe si te ha gustado todo, tus expectativas e impresiones.',
    error: 'Si establece una mala tarifa, ¿podría explicarme cuál es el problema?',
    'send-btn': 'Enviar',
  },
  'accepted-message': 'Tu comentario ha sido aceptado. ¡Gracias por utilizar nuestro servicio de entrega!',
}
