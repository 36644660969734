export default {
  alt: 'Select language',
  en: {
    alt: 'English',
    title: 'English',
  },
  es: {
    alt: 'Spanish',
    title: 'Spanish',
  },
  close: {
    alt: 'Close',
  },
}
