<template>
  <div class="confirmed-driver-info">
    <div class="agent agent--courier">
      <div class="agent__preview">
        <div class="agent__avatar agent__preview__avatar--courier">
          <div class="img" :style="backgroundImageStyle"></div>
        </div>
        <div class="agent__preview__rate">
          <i class="icon agent__preview__rate__icon">
            <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.082 4.078c-.16-.154-.073-.434.143-.46l3.055-.38a.256.256 0 00.2-.154L4.77.155a.25.25 0 01.461 0l1.289 2.93a.255.255 0 00.2.152l3.055.38c.216.027.302.307.143.461L7.66 6.268a.275.275 0 00-.077.248l.6 3.164c.042.224-.185.397-.374.285L5.124 8.39a.244.244 0 00-.248 0L2.192 9.966c-.19.111-.417-.061-.374-.285l.6-3.164a.275.275 0 00-.078-.247L.082 4.079z" fill="#FFCF23"/></svg>
          </i>
          <p class="agent__preview__rate__value">{{ rating }}</p>
        </div>
      </div>
      <div class="agent__info">
        <div class="agent__info__title">
          <div class="agent__info__title">
            <p v-for="(driverName, index) in splitName.slice(0, 3)" :key="index">{{ driverName }}</p>
          </div>
        </div>
      </div>
    </div>
    <p class="payment__id">#{{ orderData.uid }}</p>
    <div v-if="orderData.copay" class="client_info__container client_info__container--copay">
      <p class="payment__copay">Copay: ${{ orderData.copay }}</p>
      <button class="tip_btn js--btn-tip" @click="toggleTooltip">
        <i class="icon ">
          <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 2.406c-4.74 0-8.594 3.856-8.594 8.594 0 4.74 3.856 8.594 8.594 8.594 4.74 0 8.594-3.856 8.594-8.594 0-4.74-3.856-8.594-8.594-8.594zm0 3.524a1.117 1.117 0 11.064 2.233A1.117 1.117 0 0111 5.93zm2.063 9.71H9.28a.688.688 0 010-1.375h1.204v-3.78h-.688a.688.688 0 110-1.375h1.375a.688.688 0 01.688.687v4.468h1.203a.687.687 0 110 1.375z" fill="#9f9d9c"/></svg>
        </i>
      </button>
      <p class="tip_help" :class="{ active: isTooltipActive }" @click="toggleTooltip">
        {{ $t('main-page.delivering.confirmed.qr.help') }}
      </p>
    </div>
  </div>
</template>

<script>
import driverIcon from '@/assets/img/driver.png'
import { mapGetters } from 'vuex'

export default {
  name: 'staff-block-qr',
  props: {
    name: {
      type: String,
      required: true,
    },
    rate: {
      type: Number,
      required: true,
    },
    avatar: {
      type: String,
      required: true,
    },
  },
  // ['name', 'rate', 'avatar'],
  data() {
    return {
      isTooltipActive: false,
      driverIcon,
    }
  },
  computed: {
    ...mapGetters(['orderData']),
    rating() {
      const rate = Number(this.rate)
      if (rate < 9) {
        return 4.8
      }
      return (this.rate / 2).toFixed(1)
    },
    backgroundImageStyle() {
      let avatarUrl = this.avatar

      if (!this.avatar || this.avatar === '/assets/common/img/temp/avatars/1.jpg') {
        avatarUrl = this.driverIcon
      }

      return {
        backgroundImage: `url(${avatarUrl})`,
      }
    },
    splitName() {
      return this.name.split(' ')
    },
  },
  methods: {
    toggleTooltip() {
      this.isTooltipActive = !this.isTooltipActive
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
