<template>
  <div :class="['client_info', long ? 'client_info--long' : '']" style="margin-top: 5px">
    <i class="icon client_info__icon">
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 4a2.667 2.667 0 100 5.333A2.667 2.667 0 008 4zm0 4a1.333 1.333 0 110-2.667A1.333 1.333 0 018 8z" fill="#1EBEC8"/><path d="M8 16a3.515 3.515 0 01-2.874-1.466c-2.54-3.505-3.83-6.14-3.83-7.832a6.703 6.703 0 1113.407 0c0 1.692-1.288 4.327-3.829 7.832A3.514 3.514 0 018 16zM8 1.454a5.255 5.255 0 00-5.25 5.25c0 1.34 1.263 3.818 3.553 6.977a2.096 2.096 0 003.394 0c2.29-3.16 3.552-5.637 3.552-6.977A5.256 5.256 0 008 1.454z" fill="#1EBEC8"/></svg>
    </i>
    <h4 class="client_info__title">{{ orderData.status === 'Delivered' ? $t('common.delivery.to.delivered') : $t('common.delivery.to.deliver') }}:</h4>
    <p class="client_info__value">{{ formatAddress }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'delivery-to',
  props: {
    long: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['orderData', 'formatAddress']),
  },
}
</script>
