export default {
  'driver-out': 'Conductor fuera',
  'data-unavailable': 'Datos no disponibles',
  delivered: 'Entregado',
  'wrong-address': 'Dirección incorrecta',
  'not-present': 'No presente',
  'order-canceled': 'Pedido cancelado',
  'delivery-attempted': 'Intento de entrega',
  rescheduled: 'Reprogramado',
  'date-unavailaable': 'Fecha no disponible',
}
