<template>
  <div ref="mapElement" class="mapElement"></div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-routing-machine'
import 'leaflet/dist/leaflet.css'
import { mapGetters } from 'vuex'
import mapPin from '@/assets/img/map-house.png'
import carImg from '@/assets/img/map-logo.png'
import { getRouteInfo } from '@/services/routeService'

export default {
  name: 'map-component-leaflet',
  data() {
    return {
      recipientAddress: null,
      map: null,
      marker: null,
      tiles: null,
      routeControl: null,
      driver: null,
      package: null,
      driverAvatar: null,
      lastAgo: null,
      timer: null,
      recipientMarker: null,
      driverMarker: null,
    }
  },
  computed: {
    ...mapGetters(['orderData', 'routeData']),
  },
  mounted() {
    if (this.orderData.driver && this.orderData.driver.avatar !== '/assets/common/img/temp/avatars/1.jpg') {
      this.driverAvatar = this.orderData.driver.avatar
    }
    if (this.routeData?.driverCoordinates?.last_ago) {
      this.lastAgo = this.routeData.driverCoordinates.last_ago
    }
    this.recipientAddress = {
      lat: this.orderData.recipient.address.lat,
      lng: this.orderData.recipient.address.lng,
    }
    this.package = {
      lat: this.routeData.packageCoordinates.lat,
      lng: this.routeData.packageCoordinates.lng,
    }
    if (this.routeData) {
      if (this.routeData?.driverCoordinates?.lat && this.routeData?.driverCoordinates?.lng) {
        this.driver = {
          lat: this.routeData.driverCoordinates.lat,
          lng: this.routeData.driverCoordinates.lng,
        }
      }
    }

    this.initMap()
    this.timer = setInterval(this.refreshMap, 15000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {
    initMap() {
      this.map = L.map(this.$refs.mapElement)
        .setView(this.recipientAddress, 14)

      const layout = 'https://www.google.com/maps/vt?lyrs=m@221097413,traffic&hl=en&gl=com&x={x}&y={y}&z={z}'
      this.tiles = L.tileLayer(layout, {
        attribution: '',
        id: 'mapbox/streets-v11',
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
        detectRetina: true,
      })
        .addTo(this.map)

      this.refreshMap()
    },
    async refreshMap() {
      // Получение данных маршрута
      const {
        currentStop,
        stopsAway,
        driverCoordinates,
        packageCoordinates,
      } = await getRouteInfo(this.orderData.uid)
      this.$store.commit('setRouteData', {
        currentStop,
        stopsAway,
        driverCoordinates,
        packageCoordinates,
      })

      // Обновление координат пакета
      this.package = {
        lat: parseFloat(packageCoordinates.lat),
        lng: parseFloat(packageCoordinates.lng),
      }

      // Установка или обновление маркера получателя
      if (!this.recipientMarker) {
        const recipientIcon = new L.Icon({
          iconUrl: mapPin,
          iconSize: [40, 40],
          iconAnchor: [10, 15],
        })
        this.recipientMarker = L.marker(this.package, { icon: recipientIcon })
          .addTo(this.map)
      } else {
        this.recipientMarker.setLatLng(this.package)
      }

      // Установка или обновление маркера водителя
      if (driverCoordinates?.lat && driverCoordinates?.lng) {
        this.driver = {
          lat: parseFloat(driverCoordinates.lat),
          lng: parseFloat(driverCoordinates.lng),
        }

        // Если маркер водителя уже существует, удаляем его
        if (this.driverMarker) {
          this.map.removeLayer(this.driverMarker)
        }

        // Создание нового маркера водителя
        const driverIconUrl = this.driverAvatar ? this.driverAvatar : carImg
        const driverIcon = new L.Icon({
          iconUrl: driverIconUrl,
          iconSize: [35, 35],
          iconAnchor: [10, 15],
          className: 'driver-custom-icon',
        })
        this.driverMarker = L.marker(this.driver, { icon: driverIcon })
          .addTo(this.map)

        // Создание или обновление маршрута
        if (!this.routeControl) {
          this.routeControl = L.Routing.control({
            waypoints: [
              L.latLng(this.driver.lat, this.driver.lng),
              L.latLng(this.package.lat, this.package.lng),
            ],
            lineOptions: {
              styles: [{
                color: '#002BD7',
                opacity: 1,
                weight: 7,
              }],
            },
            routeWhileDragging: false,
            draggableWaypoints: false,
            addWaypoints: false,
            showAlternatives: false,
            createMarker() {
              return null
            },
          })
            .addTo(this.map)
        } else {
          this.routeControl.setWaypoints([
            L.latLng(this.driver.lat, this.driver.lng),
            L.latLng(this.package.lat, this.package.lng),
          ])
        }

        // Привязка тултипа к маркеру водителя
        if (this.lastAgo) {
          if (this.driverMarker.getTooltip()) {
            this.driverMarker.setTooltipContent(this.lastAgo)
          } else {
            this.driverMarker.bindTooltip(this.lastAgo, {
              permanent: true,
              offset: L.point(9, 7),
              direction: 'bottom',
              className: 'driver-tooltip',
            })
          }
        }
      } else if (this.driverMarker) {
        // Если нет данных водителя, удаляем маркер и маршрут
        this.map.removeLayer(this.driverMarker)
        this.driverMarker = null
        if (this.routeControl) {
          this.map.removeControl(this.routeControl)
          this.routeControl = null
        }
      }

      // Обновление видимой области карты
      const bounds = L.latLngBounds()
      bounds.extend(this.recipientMarker.getLatLng())
      if (this.driverMarker) {
        bounds.extend(this.driverMarker.getLatLng())
      }
      this.map.fitBounds(bounds, { padding: [50, 50] })
    },
  },
}
</script>

<style>
.mapElement {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.driver-custom-icon {
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #002BD7;
  position: relative;
}

.driver-tooltip {
  border-radius: 15px;
  border: 1px solid #002BD7;
  color: #002BD7;
  font-weight: 600;
}

.leaflet-routing-container .leaflet-routing-alternatives-container {
  display: none;
}

.driver-tooltip {
  font-weight: normal;
  border-width: 1px;
  position: relative;
}

.leaflet-tooltip {
  padding: 3px;

  &::before {
    background: transparent;
    border: none;
  }
}

.leaflet-marker-pane {
  z-index: 900;
}
</style>
