<template>
  <div class="wrapper-section pb-10">
    <section class="section">
      <div class="section__content">
        <text-by-status></text-by-status>
        <div class="client_info__container client_info__container--copay">
          <p class="date-time-attempted">{{ formatUSDateTime(orderData?.note_at_dt ?? orderData.status_at_dt) }}</p>
          <p class="payment__id">#{{ orderData.uid }}</p>
        </div>
        <not-delivered-order-status></not-delivered-order-status>
        <delivery-from
          v-if="orderData.client"
          :logo="orderData.client?.avatar"
        ></delivery-from>
        <delivery-to :long="true"></delivery-to>
        <driver-comment></driver-comment>

        <photo-slider
          v-if="orderData.note_pictures"
          :disabled="false"
          :photos="orderData.note_pictures"
        ></photo-slider>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextByStatus from '@/components/MainPage/Rejected/BackToPharmacy/TextByStatus/index.vue'
import NotDeliveredOrderStatus from '@/components/MainPage/Rejected/components/not-delivered-order-status.vue'
import DriverComment from '@/components/_common/driver-comment.vue'
import PhotoSlider from '@/components/_common/photo-slider.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import { formatUSDateTime } from '@/helpers'

export default {
  name: 'rejected-view',
  components: {
    DeliveryTo,
    DeliveryFrom,
    PhotoSlider,
    DriverComment,
    NotDeliveredOrderStatus,
    TextByStatus,
  },
  computed: {
    ...mapGetters(['orderData']),
  },
  methods: { formatUSDateTime },
}
</script>
