<template>
  <c-select
    v-bind="$attrs"
    :items="stateItems"
  ></c-select>
</template>
<script>
import { mapGetters } from 'vuex'
import CSelect from '@/components/UI/form/c-select.vue'

export default {
  name: 'c-address-state',
  components: {
    CSelect,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['orderData']),
    stateItems() {
      const facility = this.orderData.facility.uid
      let list = []
      switch (facility) {
        case 2:
          list = ['NY', 'NJ', 'CT', 'PA']
          break
        case 3:
          list = ['CA']
          break
        case 4:
          list = ['MA']
          break
      }
      if (this.address.state && !list.includes(this.address.state)) {
        list.push(this.address.state)
      }
      return list
    },
  },
}
</script>
