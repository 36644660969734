import axios from '@/axios'

export async function storeCall(request) {
  const response = await axios.post('call/store', request)
  return response.data
}

export async function getCall(orderId) {
  const response = await axios.get(`call/${orderId}`)
  return response.data
}
