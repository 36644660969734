<template>
  <div class="feedback">
    <button v-if="!btnActive" class="feedback__btn_modal js--btn-feedback-modal" @click="btnActiveClick()">
      <i class="icon "><svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.91 23.438c.953 0 2.291-.345 4.296-1.466 2.438-1.367 4.323-2.629 6.748-5.048 2.339-2.336 3.476-3.849 5.068-6.746 1.799-3.271 1.493-4.986 1.15-5.72-.408-.876-1.01-1.4-1.79-1.92a8.603 8.603 0 00-1.399-.742l-.135-.06c-.24-.109-.607-.273-1.07-.097-.31.117-.586.354-1.02.781-.887.875-2.098 2.823-2.546 3.781-.3.645-.5 1.071-.5 1.548 0 .56.28.99.623 1.455l.19.253c.37.488.452.629.398.88-.108.507-.92 2.014-2.255 3.347-1.335 1.33-2.798 2.091-3.306 2.2-.264.056-.407-.029-.912-.414a12.541 12.541 0 00-.224-.169c-.52-.387-.931-.661-1.477-.661h-.003c-.475 0-.882.206-1.555.546-.88.444-2.887 1.64-3.767 2.528-.43.432-.667.707-.784 1.017-.176.465-.01.83.098 1.073l.059.135c.205.487.455.954.746 1.396.52.776 1.046 1.377 1.923 1.785.45.214.944.323 1.443.318z" fill="#fff"/></svg></i>
    </button>

    <div class="feedback__modal js--feedback-modal" :class="{ 'active': btnActive }">
      <a :href="'tel:' + orderData.facility.phone_number" class="feedback__btn feedback__btn_action">
        <i class="icon feedback__btn_action__icon">
          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.91 23.438c.953 0 2.291-.345 4.296-1.466 2.438-1.367 4.323-2.629 6.748-5.048 2.339-2.336 3.476-3.849 5.068-6.746 1.799-3.271 1.493-4.986 1.15-5.72-.408-.876-1.01-1.4-1.79-1.92a8.603 8.603 0 00-1.399-.742l-.135-.06c-.24-.109-.607-.273-1.07-.097-.31.117-.586.354-1.02.781-.887.875-2.098 2.823-2.546 3.781-.3.645-.5 1.071-.5 1.548 0 .56.28.99.623 1.455l.19.253c.37.488.452.629.398.88-.108.507-.92 2.014-2.255 3.347-1.335 1.33-2.798 2.091-3.306 2.2-.264.056-.407-.029-.912-.414a12.541 12.541 0 00-.224-.169c-.52-.387-.931-.661-1.477-.661h-.003c-.475 0-.882.206-1.555.546-.88.444-2.887 1.64-3.767 2.528-.43.432-.667.707-.784 1.017-.176.465-.01.83.098 1.073l.059.135c.205.487.455.954.746 1.396.52.776 1.046 1.377 1.923 1.785.45.214.944.323 1.443.318z" fill="#fff"/></svg>
        </i>
        <span>{{ $t('footer-btn.call') }}</span>
      </a>
      <a :href="'sms:' + orderData.facility.phone_number" class="feedback__btn feedback__btn_action">
        <i class="icon feedback__btn_action__icon">
          <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.078 3.125h14.844a2.743 2.743 0 012.734 2.734v9.375a2.742 2.742 0 01-2.734 2.735h-1.953v3.906l-4.576-3.816a.391.391 0 00-.25-.09H5.078a2.742 2.742 0 01-2.734-2.735V5.86a2.743 2.743 0 012.734-2.734z" stroke="#fff" stroke-width="2" stroke-linejoin="round"/><path d="M17.188 12.11a1.562 1.562 0 110-3.126 1.562 1.562 0 010 3.125zM12.5 12.11a1.562 1.562 0 110-3.126 1.562 1.562 0 010 3.125zM7.813 12.11a1.562 1.562 0 110-3.125 1.562 1.562 0 010 3.124z" fill="#fff"/></svg>
        </i>
        <span>{{ $t('footer-btn.text') }}</span>
      </a>
      <button class="feedback__btn feedback__btn_close" @click="btnActiveClick()">
        <i class="icon feedback__btn_close__icon">
          <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g opacity=".4" stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M25.156 25.156L9.844 9.844M25.156 9.844L9.844 25.156"/></g></svg>
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'footer-btn',
  data() {
    return {
      btnActive: false,
    }
  },
  computed: {
    ...mapGetters(['deliveringState', 'orderData']),
  },
  methods: {
    btnActiveClick() {
      this.btnActive = !this.btnActive
    },
  },
}
</script>
