<template>
  <div class="driver_report__comment" style="margin: 10px 0">
    <h5 class="info_title driver_report__comment__title">{{ $t('common.delivery.comment') }}:</h5>
    <p class="info_desc">{{ driverComment }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { trim } from 'lodash'

export default {
  name: 'driver-comment',
  computed: {
    ...mapGetters(['driverNote']),
    driverComment() {
      let result = ''
      if (this.driverNote) {
        const content = this.driverNote?.content?.contents
        const comment = this.driverNote?.content?.comments

        if (trim(content)) {
          result += `${content}.`
        }

        if (trim(comment) && trim(content) !== trim(comment)) {
          result += ` ${comment}.`
        }

        return result
      }

      return '-'
    },
  },
}
</script>
