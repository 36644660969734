<template>
  <div>
    <template v-if="deliveringState.state === 'rescheduled'">
      <order-rescheduled></order-rescheduled>
    </template>
    <template v-else>
      <driver-out v-if="driverOutConditions"></driver-out>
      <at-the-pharmacy v-if="atThePharmacyConditions"></at-the-pharmacy>
      <pickup-occurred v-if="pickupOccurredConditions"></pickup-occurred>
      <ready-for-delivery v-if="readyForDeliveryConditions"></ready-for-delivery>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrderRescheduled
  from '@/components/MainPage/Delivering/NotConfirmed/components/TextByStatus/OrderRescheduled.vue'
import DriverOut from './DriverOut.vue'
import AtThePharmacy from './AtThePharmacy.vue'
import PickupOccurred from './PickupOccured.vue'
import ReadyForDelivery from './ReadyForDelivery.vue'

export default {
  name: 'text-by-status',
  components: {
    OrderRescheduled,
    ReadyForDelivery,
    PickupOccurred,
    AtThePharmacy,
    DriverOut,
  },
  computed: {
    ...mapGetters(['orderData', 'deliveringState']),
    driverOutConditions() {
      return this.orderData.status_slug === 'driver-out'
    },
    atThePharmacyConditions() {
      return this.orderData.status_slug === 'ready-to-print' || this.orderData.status_slug === 'ready-for-pickup'
    },
    pickupOccurredConditions() {
      return this.orderData.status_slug === 'pickup-occurred'
    },
    readyForDeliveryConditions() {
      return this.orderData.status_slug === 'ready-for-delivery' || this.orderData.status_slug === 'route-optimized'
    },
    pendingBackToPharmacy() {
      return this.orderData.status_slug === 'returned' && this.orderData.sub_status_slug === 'pending-back-to-pharmacy'
    },
  },
}
</script>
