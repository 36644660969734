<template>
  <div class="map-block">
    <l-map ref="mapRef" v-model:zoom="zoom" :center="center">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <l-marker :lat-lng="orderGeo" :draggable="true" @dragend="updateGeo"></l-marker>
    </l-map>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import 'leaflet/dist/leaflet.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import useAddress from '@/compositions/Address'
import { nGps } from '@/utils/Address'
import { awaitCondition } from '@/helpers'

export default {
  name: 'c-map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    facilityGeo: {
      type: Object,
      default: () => ({
        lat: 40.797697,
        lng: '-73.645339',
      }),
    },
    address: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:address'],
  setup(props, { emit }) {
    const mapRef = ref(null)

    const computedAddress = computed({
      get() {
        return props.address
      },
      set(v) {
        emit('update:address', v)
      },
    })
    const {
      controlLoader,
      orderGeo,
    } = useAddress(computedAddress)

    const center = computed(() => {
      if (orderGeo.value?.lat && orderGeo.value?.lng) {
        return [orderGeo.value.lat, orderGeo.value.lng]
      }
      return [props.facilityGeo.lat, props.facilityGeo.lng]
    })

    const updateGeo = e => {
      computedAddress.value.adjusted = 'yes'
      computedAddress.value.source = 'Manual'
      computedAddress.value.lat = nGps(e.target._latlng.lat)
      computedAddress.value.lng = nGps(e.target._latlng.lng)
    }

    const zoom = computed(() => {
      if (orderGeo.value.lat && orderGeo.value.lng) {
        return 18
      }
      return 9
    })

    onMounted(() => {
      awaitCondition(() => !!mapRef.value?.leafletObject)
        .then(() => {
          controlLoader.addTo(mapRef.value.leafletObject)
        })
    })

    return {
      mapRef,
      zoom,
      center,
      orderGeo,
      updateGeo,
    }
  },
}
</script>

<style lang="scss" scoped>
.map-block {
  overflow: hidden;
  border-radius: 20px;
  height: 350px;
  width: 100%;
}
</style>
