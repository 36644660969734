export default {
  buttons: {
    reschedule: 'Reprogramar',
    'reschedule-change-address': 'Reprogramar/Cambiar dirección',
    'add-time': 'Añadir tiempo',
  },
  'pickup-time-min-title': 'Hora de recogida min',
  'pickup-time-max-title': 'Hora máxima de recogida',
  'pickup-date': 'Elija una fecha de entrega',
  'change-address': {
    title: 'Has cambiado tu dirección',
    description: 'Permítanos un tiempo para procesar su pedido y le proporcionaremos el enlace de seguimiento.',
    error: 'Lo sentimos, no realizamos entregas a la dirección seleccionada, por favor ingrese otra dirección',
  },
}
