<template>
  <button
    class="btn form__btn"
    :class="{'disabled': disabled }"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 't-button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.form__btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
