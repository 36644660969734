<template>
  <h2 class="section__title section__title--centred" style="font-size: 19px; margin-bottom: 10px;">{{ title }}</h2>
  <p v-if="orderData.client" class="info_desc">
    {{ $t('signature.info') }} <span>{{ orderData?.client.business_name }}</span>
  </p>
  <p class="info_desc">{{ $t('signature.title') }}</p>

  <div v-if="orderData.recipient" class="client_info__container client_info__container--signature">
    <div class="client_info  client_info--signature">
      <i class="icon  client_info__icon client_info__icon--signature">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.094 5.063C11.956 6.92 10.547 8.438 9 8.438S6.041 6.921 5.906 5.062C5.766 3.13 7.136 1.688 9 1.688c1.863 0 3.234 1.477 3.094 3.375zM9 10.688c-3.059 0-6.163 1.687-6.737 4.872-.07.384.147.752.55.752h12.374c.402 0 .62-.368.55-.752-.574-3.185-3.679-4.873-6.737-4.873z" stroke="#1EBEC8" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </i>
      <h4 class="client_info__title">{{ $t('signature.fields.name') }}</h4>
      <p class="client_info__value">{{ orderData.recipient.name }}</p>
    </div>

    <div v-if="orderData.recipient" class="client_info  client_info--signature">
      <i class="icon  client_info__icon client_info__icon--signature">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.855 13.147c-.558-.562-1.91-1.383-2.566-1.713-.854-.43-.925-.465-1.596.033-.447.333-.745.63-1.27.519-.524-.112-1.663-.743-2.66-1.737-.998-.994-1.665-2.166-1.776-2.688-.113-.523.19-.818.52-1.266.464-.633.429-.738.032-1.593-.31-.664-1.155-2.004-1.72-2.56-.603-.596-.603-.49-.993-.329a5.63 5.63 0 00-.908.485c-.562.373-.874.684-1.093 1.15-.218.467-.316 1.56.811 3.609 1.127 2.048 1.919 3.095 3.555 4.728 1.638 1.632 2.897 2.51 4.737 3.543 2.277 1.275 3.15 1.026 3.618.808.468-.217.78-.527 1.155-1.09.19-.287.352-.59.485-.907.162-.387.267-.387-.33-.99l-.001-.002z" stroke="#1EBEC8" stroke-width="1.6"/></svg>
      </i>
      <h4 class="client_info__title">{{ $t('signature.fields.phone') }}</h4>
      <p class="client_info__value">{{ orderData.recipient.phones.call }}</p>
    </div>

    <div v-if="orderData.recipient" class="client_info client_info--long client_info--signature">
      <i class="icon  client_info__icon client_info__icon--signature">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8 4a2.667 2.667 0 100 5.333A2.667 2.667 0 008 4zm0 4a1.333 1.333 0 110-2.667A1.333 1.333 0 018 8z" fill="#1EBEC8"/><path d="M8 16a3.515 3.515 0 01-2.874-1.466c-2.54-3.505-3.83-6.14-3.83-7.832a6.703 6.703 0 1113.407 0c0 1.692-1.288 4.327-3.829 7.832A3.514 3.514 0 018 16zM8 1.454a5.255 5.255 0 00-5.25 5.25c0 1.34 1.263 3.818 3.553 6.977a2.096 2.096 0 003.394 0c2.29-3.16 3.552-5.637 3.552-6.977A5.256 5.256 0 008 1.454z" fill="#1EBEC8"/></svg>
      </i>
      <h4 class="client_info__title">{{ $t('signature.fields.address') }}</h4>
      <p class="client_info__value">{{ formatAddress }}</p>
    </div>

    <div v-if="orderData.rx_number" class="client_info client_info--long client_info--signature">
      <i class="icon  client_info__icon ">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.938 1.688H5.061a1.687 1.687 0 00-1.687 1.687v11.25a1.687 1.687 0 001.688 1.688h7.875a1.687 1.687 0 001.687-1.688V3.375a1.687 1.687 0 00-1.688-1.688zM6.188 4.5h5.625M6.188 7.313h5.625M6.188 10.125H9" stroke="#1EBEC8" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </i>
      <h4 class="client_info__title">{{ $t('signature.fields.rx-number') }}</h4>
      <p class="client_info__value">{{ orderData.rx_number }}</p>
    </div>
  </div>

  <div class="client_info__container form">
    <signature-component
      ref="signatureComponent"
      :changed-signature="handleChangedSignature"
    ></signature-component>
    <t-button
      :class="'success'"
      style="margin-top: 0;"
      @click="sendSignature"
    >
      {{ $t('signature.action-btn') }}
    </t-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SignatureComponent from '@/components/MainPage/Delivering/NotConfirmed/components/signature-component.vue'
import TButton from '@/components/UI/t-button.vue'
import axios from 'axios'
import { commUrl } from '@/config'
import { useToast } from 'vue-toastification'
import CryptoJS from 'crypto-js'
import { uploadSign } from '@/services/orderService'

export default {
  name: 'signature-pad',
  components: {
    TButton,
    SignatureComponent,
  },
  computed: {
    ...mapGetters(['orderData', 'formatAddress']),
    title() {
      if (this.orderData.signature) {
        return 'You have already signed. Do you want to change your signature?'
      }

      return 'Signature required'
    },
  },
  methods: {
    handleChangedSignature() {
      this.emptySignature = false
    },
    base64ToBlob(base64, mimeType) {
      const bytes = atob(base64.split(',')[1])
      const array = new Uint8Array(bytes.length)

      for (let i = 0; i < bytes.length; i++) {
        array[i] = bytes.charCodeAt(i)
      }

      return new Blob([array], { type: mimeType })
    },
    createFile(blob) {
      return new File(
        [blob],
        `${this.orderData.uid}_signature.svg`,
        { type: 'image/svg' },
      )
    },
    setFormData(file, prefix, hash) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('prefix', prefix)
      fd.append('hash', hash)

      return fd
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    },
    async getFileHash(file) {
      const arrayBuffer = await this.readFileAsArrayBuffer(file)
      const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer)
      return CryptoJS.MD5(wordArray)
        .toString()
    },
    async uploadSignature() {
      if (this.$refs.signatureComponent) {
        const signatureBase64 = await this.$refs.signatureComponent.getSignature()

        if (signatureBase64) {
          const signatureBlob = this.base64ToBlob(signatureBase64, 'image/svg')
          const signatureFile = this.createFile(signatureBlob)

          const hashMd5 = await this.getFileHash(signatureFile)

          const formData = this.setFormData(signatureFile, `${this.orderData.uid}_signature`, hashMd5)

          const response = await axios.post(`${commUrl}upload-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          return {
            response,
            hash: hashMd5,
          }
        }
      }

      return null
    },
    async sendSignature() {
      this.$store.commit('setLoaderVisible', true)
      const toast = useToast()

      let signatureLink = null
      let signatureHash = null

      const signatureUpload = await this.uploadSignature()

      if (signatureUpload && signatureUpload.response.status === 200) {
        signatureLink = signatureUpload.response.data.link
        signatureHash = signatureUpload.hash
      }

      const data = {
        signature_link: signatureLink,
        signature_hash: signatureHash,
      }

      const response = await uploadSign(this.orderData.uid, data)

      if (response.success) {
        toast.success(response.success)
        this.handleDelay(() => window.location.reload())
      } else if (response.error) {
        toast.error(response.error)
        this.handleDelay(() => {
          this.loading = false
        })
      }
      this.$store.commit('setLoaderVisible', false)
    },
    handleDelay(callback) {
      setTimeout(() => {
        callback()
      }, 1000)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
