import { createStore } from 'vuex'
import { formatDate, formatUSDateTime } from '@/helpers'
import { phones } from '@/config'

export default createStore({
  state: {
    urlParams: '',
    loaderVisible: false,
    deliveringState: {
      state: null,
      subState: null,
    },
    statuses: {},
    orderData: {},
    routeData: null,
    driverNote: {},
    rescheduleData: {
      date_to_deliver: null,
      pickup_time_min: null,
      pickup_time_max: null,
      promised_eta: null,
      order_type: null,
      special_instruction: null,
    },
    feedback: {
      comment: '',
      order_uid: null,
      driver_uid: null,
      dispatcher_uid: null,
      client_uid: null,
      rate_driver: 0,
      rate_dispatcher: 0,
      rate_pharmacy: 0,
      ip: null,
      useragent: null,
      referrer: null,
      is_finalized: null,
      contact_me: false,
    },
    tips: {
      type: null,
      amount: 2,
      amountString: '2',
      card: {
        number: null,
        exp: null,
        cvc: null,
      },
    },
    callData: {},
    callFeedbackData: {
      rate: 0,
      comment: '',
    },
    phones: {},
  },
  getters: {
    deliveringState: state => state.deliveringState,
    loaderVisible: state => state.loaderVisible,
    rescheduleData: state => state.rescheduleData,
    confirmationData: state => state.confirmationData,
    orderData: state => state.orderData,
    routeData: state => state.routeData,
    driverNote: state => state.driverNote,
    orderStatuses: state => state.orderStatuses,
    orderTypes: state => state.orderTypes,
    feedback: state => state.feedback,
    tips: state => state.tips,
    urlParams: state => state.urlParams.split('='),
    dateTimeAttempted: state => {
      if (state.deliveringState.state === 'need-reschedule') {
        return formatUSDateTime(state.orderData?.note_at_dt ?? state.orderData.status_at_dt)
      }

      return null
    },
    dateToDeliver: state => {
      const { eta } = state.orderData
      if (!eta.arrivalDate && !eta.date) {
        return null
      }

      if (eta.arrivalDate) {
        return formatDate(eta.arrivalDate)
      }
      return formatDate(eta.date)
    },
    timeToDeliver: state => {
      const { eta } = state.orderData

      let time = null
      if (!eta.from && !eta.to) {
        time = 'before 9:00 PM'
      }

      if (eta.from && !eta.to) {
        time = `${eta.from} - 9:00 PM`
      }

      if (!eta.from && eta.to) {
        time = `8:00 AM - ${eta.to}`
      }
      return time
    },
    dateTimeToDeliver: state => {
      const { eta } = state.orderData
      if (!eta.arrivalDate && !eta.date) {
        return 'Date unavailable'
      }

      const date = eta.arrivalDate || eta.date

      let time = null
      if (!eta.from && !eta.to) {
        time = 'before 9:00 PM'
      }

      if (eta.from && !eta.to) {
        time = `${eta.from} - 9:00 PM`
      }

      if (!eta.from && eta.to) {
        time = `8:00 AM - ${eta.to}`
      }

      return time ? `${time}<br>${formatDate(date)}` : formatDate(date)
    },
    formatAddress: state => {
      const { address } = state.orderData.recipient
      let formattedAddress = address.address

      if (!address.address.includes(address.apt) && address.apt) {
        formattedAddress += ` (apt: ${address.apt})`
      }

      if (!address.address.includes(address.city)) {
        formattedAddress += `, ${address.city}`
      }

      if (!address.address.includes(address.state)) {
        formattedAddress += `, ${address.state}`
      }

      if (!address.address.includes(address.zip)) {
        formattedAddress += `, ${address.zip}`
      }

      return formattedAddress
    },
    allowTips: state => state.orderData?.driver
      && state.orderData?.client?.customize?.allow_tips === 'yes'
      && state.orderData?.driver?.customize?.allow_tips === 'yes'
      && (state.orderData?.status_slug === 'driver-out' || state.orderData?.status_slug === 'delivered'),
    disableReschedule: state => {
      if (state.orderData.reschedule_count > 3) {
        return true
      }

      if (state.orderData.subtype_fridge === 'yes') {
        return true
      }

      if (state.orderData.client.customize.restrict_reschedule === 'yes') {
        return true
      }

      if (state.deliveringState.state === 'rescheduled') {
        return true
      }

      return false
    },
    callData: state => state.callData,
    callFeedbackData: state => state.callFeedbackData,
    phonesNumbers: state => state.phones,
  },
  mutations: {
    setUrlParams(state, payload) {
      state.urlParams = payload
    },
    setOrderData(state, payload) {
      state.orderData = payload
    },
    setStatuses(state, payload) {
      state.statuses = payload
    },
    setRouteData(state, payload) {
      state.routeData = payload
    },
    setDriverNote(state, payload) {
      state.driverNote = payload
    },
    setFeedback(state, payload) {
      state.feedback = payload
    },
    setRescheduleData(state, payload) {
      state.rescheduleData = payload
    },
    setRescheduleDataParse(state, payload) {
      state.rescheduleData = { ...state.rescheduleData, ...payload }
    },
    setDeliveringState(state, payload) {
      state.deliveringState = payload
    },
    setLoaderVisible(state, payload) {
      state.loaderVisible = payload
    },
    setCallData(state, payload) {
      state.callData = payload
    },
    setPhones(state, facilityId) {
      state.phones = facilityId ? phones[facilityId] : phones[2]
    },
    setTipsAmount(state, payload) {
      state.tips.amount = Number(String(payload)
        .replace(/\D/g, ''))
    },
    setTipsAmountString(state, payload) {
      state.tips.amountString = payload
    },
  },
})
