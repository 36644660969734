export default {
  from: {
    new: 'Nuevo',
    'delivery-from': 'Entrega desde',
  },
  to: {
    delivered: 'Entregado a',
    deliver: 'Entregar a',
  },
  comment: 'Comentario del conductor',
}
