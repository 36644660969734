<template>
  <delivered-info></delivered-info>
  <delivered-feedback></delivered-feedback>
  <div v-if="allowTips" class="wrapper-section pb-10">
    <div class="tips-section-border">
      <tips-component></tips-component>
    </div>
  </div>
</template>

<script>
import TipsComponent from '@/components/_common/TipsComponents/tips-component.vue'
import { mapGetters } from 'vuex'
import DeliveredInfo from './components/info.vue'
import DeliveredFeedback from './components/feedback.vue'

export default {
  name: 'delivered-view',
  components: { TipsComponent, DeliveredInfo, DeliveredFeedback },
  computed: {
    ...mapGetters(['allowTips']),
  },
}
</script>
