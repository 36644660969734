<template>
  <div class="client_info__container delivery__condition delivery__condition--error">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.008 1.991a5.667 5.667 0 00-8.018 0 5.667 5.667 0 000 8.018 5.667 5.667 0 008.018 0 5.667 5.667 0 000-8.018zM6 9.544a3.523 3.523 0 01-1.64-.401L9.143 4.36c1.219 2.328-.467 5.183-3.143 5.183zm0-7.087c.577 0 1.136.137 1.64.4L2.857 7.64c-1.22-2.329.466-5.183 3.142-5.183z" fill="#FF3C5F"/></svg>
      </i>
      <span class="delivery__condition__text">{{ $t('common.approve-type.non-contract-delivery.label') }}</span>
    </h3>
    <p class="delivery__condition__desc">
      {{ $t('common.approve-type.non-contract-delivery.description') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'non-contact-delivery',
}
</script>
