export default {
  title: 'You may now close this page. If you have any questions, please feel free to call us at',
  'call-rating': 'Call Rating',
  comment: {
    title: 'Please leave your comments:',
    placeholder: 'Write down if you liked everything, your expectations and impressions',
    error: 'If you set a bad rate, so please explain what is wrong?',
    'send-btn': 'Send',
  },
  'accepted-message': 'You feedback has been accepted accepted. Thank you for using our delivery service!',
}
