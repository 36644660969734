<template>
  <div class="wrapper-section">
    <div class="section">
      <div class="section__content order">
        <h2 class="section__title">{{ $t('main-page.delivering.confirmed-no-route.title') }} {{ dateToDeliver }}</h2>
        <p class="section-subtitle">{{ $t('main-page.delivering.confirmed-no-route.title') }}</p>
        <copay-block></copay-block>

        <div class="client_info__container">
          <promised-eta></promised-eta>
          <delivery-from v-if="orderData.client" :long="true"></delivery-from>
          <delivery-to :long="true"></delivery-to>
        </div>

        <approve-type></approve-type>
        <confirmation-form></confirmation-form>
      </div>
    </div>
  </div>
  <div v-if="allowTips" class="wrapper-section py-10">
    <div class="tips-section-border">
      <tips-component></tips-component>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CopayBlock from '@/components/_common/copay-block.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import ApproveType from '@/components/_common/ApproveType/index.vue'
import ConfirmationForm from '@/components/MainPage/Delivering/NotConfirmed/components/confirmation-form.vue'
import TipsComponent from '@/components/_common/TipsComponents/tips-component.vue'
import PromisedEta from '@/components/_common/promised-eta.vue'
import { formatDate } from '@/helpers'

export default {
  name: 'confirmed-no-route',
  components: {
    PromisedEta,
    TipsComponent,
    ConfirmationForm,
    ApproveType,
    DeliveryTo,
    DeliveryFrom,
    CopayBlock,
  },
  computed: {
    ...mapGetters(['orderData', 'urlParams', 'allowTips', 'deliveringState', 'dateToDeliver']),
    dateToDeliver() {
      if (this.dateToDeliver) {
        return `for ${this.dateToDeliver}`
      }

      return null
    },
  },
}
</script>

<style scoped>

</style>
