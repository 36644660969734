import { format, isToday, isTomorrow, parseISO } from 'date-fns'
import moment from 'moment'

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000)
  if (isToday(date)) {
    return `Today at ${format(date, 'hh:mm a')}`
  }
  return `${format(date, 'MM/dd/yyyy')} at ${format(date, 'hh:mm a')}`
}

export function formatTime(time, timeFormat = 'h:mma') {
  const end = moment(time)

  return end.utcOffset(end._tzm)
    .format(timeFormat)
}

export function formatDate(dateString) {
  const date = parseISO(dateString)

  if (isToday(date)) {
    return 'Today'
  }
  if (isTomorrow(date)) {
    return 'Tomorrow'
  }

  return format(date, 'MM/dd/yyyy')
}

export function formatUSDate(dateString) {
  const date = parseISO(dateString)
  return format(date, 'MM/dd/yyyy')
}

export function formatUSDateTime(dateTimeString) {
  const date = new Date(dateTimeString)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes} ${ampm}`
  return `${strTime} ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export function convertTime12to24(time12h) {
  const [time, modifier] = time12h.split(' ')

  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':')
  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM' && hours !== '12') {
    hours = parseInt(hours, 10) + 12
  }

  return `${hours}:${minutes}`
}

export function estimatedMinutesToTime(estimatedMinutes) {
  const now = new Date()
  now.setMinutes(now.getMinutes() + estimatedMinutes)

  const hours = now.getHours()
  const minutes = now.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

  return `${formattedHours}:${formattedMinutes} ${ampm}`
}

export function formatPhoneNumber(number) {
  const str = number.toString()
  return str.replace(/(\+\d)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
}

export function removeSecondsFromTime(timeWithSeconds) {
  const [hours, minutes] = timeWithSeconds.split(':')
  return `${hours}:${minutes}`
}

export async function awaitCondition(condition, timeout = 200) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const cond = condition()
      if (cond === false) return
      clearInterval(interval)
      if (cond instanceof Promise) {
        cond
          .then(data => resolve(data))
          .catch(data => reject(data))
      } else {
        resolve(cond)
      }
    }, timeout)
  })
}
