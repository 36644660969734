import tips from './tips'
import copay from './copay'
import delivery from './delivery'

export default {
  'approve-type': {
    'face-2-face-id-required': {
      label: 'Face-to-Face ID & Signature Required',
      description_1: 'Prescription will be delivered to the assigned patient only. Both ID and signature are required by the recipient, and the ID must match the recipients name.',
      description_2: 'Driver will take a picture of the patient\'s ID and the door upon delivery.',
    },
    'face-2-face-not-required': {
      label: 'Face-to-Face Signature Not Required',
      description: 'Signature is required by any 18+ Adult who lives with the patient. Driver will take a picture of the patient’s door.',
    },
    'face-2-face-required': {
      label: 'Face-to-Face Signature Required',
      description: 'Signature is required by any person who lives with the patient. Driver will take a picture of the patient’s door.',
    },
    'non-contract-delivery': {
      label: 'No-contact Delivery',
      description: 'Driver will leave the package by the door and take a picture, without attempting face-to-face delivery.',
    },
    'online-signature': {
      label: 'Online Signature',
      description_1: 'Driver will leave prescription by the door and take a picture, only',
      description_2: 'if recipient signs online before the delivery. Without online signature, the delivery method will',
      description_3: 'proceed as \'Face-to-Face Signature Required\'.',
    },
    'signature-optional': {
      label: 'Signature Optional',
      description: 'Driver will attempt face-to-face delivery. If nobody is home,driver will leave the package by the door and take a picture.',
    },
  },
  tips,
  copay,
  delivery,
  wait: 'Please wait...',
  'promised-eta': 'Promised ETA:',
  reschedule: 'Reschedule/Change address',
}
