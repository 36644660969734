<template>
  <div class="client_info__container delivery__condition delivery__condition--face_id">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.076 0H1.625A1.627 1.627 0 000 1.625v5.907a1.627 1.627 0 001.625 1.624h9.451a1.627 1.627 0 001.625-1.624V1.625A1.627 1.627 0 0011.076 0zM4.542 4.74a1.329 1.329 0 10-1.217 0 .436.436 0 00-.18.046l-.506.253c-.35.175-.571.533-.571.925v.52c0 .246.198.444.443.444h2.953a.443.443 0 00.443-.443v-.502c0-.409-.24-.78-.615-.945l-.588-.26a.447.447 0 00-.162-.038zm2.695 1.315h2.953a.443.443 0 000-.886H7.237a.443.443 0 000 .886zm0-2.067h1.476a.443.443 0 000-.887H7.237a.443.443 0 000 .886z" fill="#9655FF"/></svg>
      </i>
      <span class="delivery__condition__text">{{ $t('common.approve-type.face-2-face-id-required.label') }}</span>
    </h3>
    <p class="delivery__condition__desc">
      {{ $t('common.approve-type.face-2-face-id-required.description_1') }}
      {{ $t('common.approve-type.face-2-face-id-required.description_2') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'face2-face-i-d-required',
}
</script>
