<template>
  <div class="wrapper-section pb-10">
    <div class="section">
      <div class="section__content">
        <h2 class="section__title section__title--error">{{ titleText }}</h2>
        <p class="section-subtitle">{{ $t('main-page.rescadule.subtitle') }}</p>
        <copay-block :date-time-attempted="dateTimeAttempted"></copay-block>
        <delivery-from
          v-if="orderData.client"
          :logo="orderData.client?.avatar"
        ></delivery-from>

        <div class="agent__wrapper">
          <staff-block
            v-if="orderData.driver"
            role-name="Driver"
            :name="orderData.driver.name"
            :rate="orderData.driver.rate"
            :avatar="orderData.driver.avatar"
          ></staff-block>
          <staff-block
            v-if="orderData.dispatcher"
            role-name="Dispatcher"
            :name="orderData.dispatcher.name"
            :rate="orderData.dispatcher.rate"
            :avatar="orderData.dispatcher.avatar"
          ></staff-block>
        </div>

        <driver-comment></driver-comment>

        <photo-slider
          v-if="orderData.note_pictures"
          :disabled="false"
          :photos="orderData.note_pictures"
        ></photo-slider>
        <reschedule-component
          v-if="!cantBeRescheduled"
          :require-change-address="deliveringState.subState === 'wrong-address'"
          :not-present="true"
        ></reschedule-component>
      </div>
    </div>
  </div>
</template>

<script>
import DriverComment from '@/components/_common/driver-comment.vue'
import DeliveryFrom from '@/components/_common/delivery-from.vue'
import CopayBlock from '@/components/_common/copay-block.vue'
import PhotoSlider from '@/components/_common/photo-slider.vue'
import RescheduleComponent from '@/components/ReschedulePage/index.vue'
import { mapGetters } from 'vuex'
import StaffBlock from '@/components/_common/staff-block.vue'
import { formatPhoneNumber } from '@/helpers'

export default {
  name: 'need-reschedule',
  components: {
    StaffBlock,
    RescheduleComponent,
    PhotoSlider,
    CopayBlock,
    DeliveryFrom,
    DriverComment,
  },
  computed: {
    ...mapGetters(['orderData', 'deliveringState', 'dateTimeAttempted']),
    cantBeRescheduled() {
      if (this.orderData.reschedule_count > 3) {
        return true
      }

      if (this.orderData.subtype_fridge === 'yes') {
        return true
      }

      if (this.orderData.client) {
        if (this.orderData.client.customize.restrict_reschedule === 'yes') {
          return true
        }
      } else {
        return true
      }

      return false
    },
    titleText() {
      if (this.orderData.reschedule_count >= 3) {
        return `Your order can't be rescheduled more than 3 times. Please call ${formatPhoneNumber(this.orderData.facility.phone_number)}`
      }

      if (this.deliveringState.subState === 'wrong-address') {
        return 'Our driver had trouble locating your address, please provide us with the correct address details'
      }

      return 'Our driver visited your address, but unfortunately, no one answered the door'
    },
  },
}
</script>
