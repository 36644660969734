<template>
  <div :class="notPresent ? '' : 'wrapper-section pb-10'">
    <div :class="notPresent ? '' : 'section'">
      <div class="section__content order">
        <h2 v-if="deliveringState.state !== 'need-reschedule'" class="section__title">Reschedule/Change address</h2>

        <delivery-to v-if="!changeAddress" :long="true"></delivery-to>

        <div class="delivery__reschedule_tabs">
          <button
            v-if="!requireChangeAddress"
            class="delivery__reschedule_tabs__item"
            :class="{ active: !changeAddress }"
            @click="changeAddress = false"
          >
            {{ $t('reschedule.buttons.reschedule') }}
          </button>
          <button
            class="delivery__reschedule_tabs__item"
            :class="{ active: changeAddress }"
            @click="changeAddress = true"
          >
            {{ $t('reschedule.buttons.reschedule-change-address') }}
          </button>
        </div>

        <change-address v-if="changeAddress"></change-address>

        <v-radio
          v-model="rescheduleData.order_type"
          title="Select Order Type"
          :options="allowedTypes"
          style="margin-top: 5px"
          @change="setOrderType($event.target.value)"
        ></v-radio>

        <div class="client_info__container form">
          <div class="form__input">
            <h5 class="info_title form__input__title info_title">
              {{ $t('reschedule.pickup-date') }}
            </h5>
            <div class="form__input__field_wrapper">
              <calendar-selector></calendar-selector>
            </div>
          </div>
          <div class="form__input form__input--small" style="display: none">
            <h5 class="info_title form__input__title info_title--small">{{ $t('reschedule.pickup-time-min-title') }}</h5>
            <div class="form__input__field_wrapper">
              <input
                v-model="rescheduleData.pickup_time_min"
                class="form__input__field_text"
                type="time"
                @input="setTime()"
              >
              <p class="form__input__placeholder">
                {{ $t('reschedule.buttons.add-time') }}
              </p>
            </div>
          </div>
          <div class="form__input form__input--small" style="display: none">
            <h5 class="info_title form__input__title info_title--small">
              {{ $t('reschedule.pickup-time-max-title') }}
            </h5>
            <div class="form__input__field_wrapper">
              <input
                v-model="rescheduleData.pickup_time_max"
                class="form__input__field_text"
                type="time"
                @input="setTime()"
              >
              <p class="form__input__placeholder">
                {{ $t('reschedule.buttons.add-time') }}
              </p>
            </div>
          </div>
          <v-radio
            v-if="promiseEtaOptions.length"
            v-model="rescheduleData.promised_eta"
            title="Preferred ETA"
            :title-small="true"
            :options="promiseEtaOptions"
            style="margin-bottom: 10px;"
            @change="setTimeEta($event.target.value)"
          ></v-radio>
          <t-input
            v-model="orderData.instructions.recipient"
            title="Recipient’s Special Instructions"
            placeholder="Add special instruction"
          ></t-input>
        </div>

        <t-button
          :class="'success'"
          :disabled="disableReschedule"
          style="margin-top: 0"
          @click="reschedule()"
        >
          {{ $t('reschedule.buttons.reschedule') }}
        </t-button>

        <router-link
          v-if="!notPresent"
          :to="{
            name: 'main_page',
            query: {
              [urlParams[0]]: urlParams[1]
            }
          }"
          class="btn form__btn outline"
          style="margin-top: 0;"
        >
          Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useTypes } from '@/compositions/Address'
import { useToast } from 'vue-toastification'
import TButton from '@/components/UI/t-button.vue'
import VRadio from '@/components/UI/form/v-radio.vue'
import TInput from '@/components/UI/form/t-input.vue'
import DeliveryTo from '@/components/_common/delivery-to.vue'
import { rescheduleOrder } from '@/services/rescheduleService'
import axios from 'axios'
import { formatTime, awaitCondition } from '@/helpers'
import { dashUrl } from '@/config'
import CalendarSelector from '@/components/ReschedulePage/components/calendar-selector.vue'
import { getOrderInfo } from '@/services/orderService'
import ChangeAddress from './change-address.vue'

export default {
  name: 'reschedule-component',
  components: {
    CalendarSelector,
    DeliveryTo,
    VRadio,
    TInput,
    TButton,
    ChangeAddress,
  },
  props: {
    requireChangeAddress: {
      type: Boolean,
      default: false,
    },
    notPresent: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return useTypes()
  },
  data() {
    return {
      queryParam: null,
      services: {},
      allowedTypes: [],
      promiseEtaOptions: [],
      changeAddress: false,
      disabled: false,
      regularAllowedClinets: [
        219228192,
        219215008,
        219207186,
        219219138,
        219215451,
        219214383,
        219207373,
        219207118,
      ],
    }
  },
  computed: {
    ...mapGetters(['rescheduleData', 'orderData', 'urlParams', 'deliveringState', 'disableReschedule']),
  },
  watch: {
    services() {
      this.collectTypes()
    },
    changeAddress(val) {
      if (!val) {
        this.rescheduleData.address = { ...JSON.parse(JSON.stringify(this.orderData.recipient.address)) }
      }
    },
    types: {
      deep: true,
      handler() {
        this.collectTypes()
      },
    },
  },
  mounted() {
    if (this.deliveringState.state === 'rescheduled') {
      this.$router.push({
        name: 'main_page',
        query: {
          [this.urlParams[0]]: this.urlParams[1],
        },
      })
    }
    this.changeAddress = this.requireChangeAddress
    this.getServicesList()
  },
  methods: {
    collectTypes() {
      this.allowedTypes = []
      let i = 0
      let temp = null
      for (const key in this.services) {
        // allowed types
        if (!['9', '22', '25', '27'].includes(key)) {
          continue
        }
        if (this.types[key] === false) {
          continue
        }

        if (key === '9') {
          temp = {
            value: key,
            label: 'Next Day 8:00am-9:00pm',
            checked: false,
          }
        } else if (
          [22, 27].includes(this.orderData?.deliver?.type)
          || this.orderData?.client?.customize?.allow_change_delivery_time === 'yes'
        ) {
          this.allowedTypes.push({
            value: key,
            label: this.services[key].name,
            checked: i === 0,
          })
          if (i === 0) {
            this.setOrderType(key)
          }
          i++
        }
      }

      if (
        [22, 27].includes(this.orderData?.deliver?.type)
            && !this.regularAllowedClinets.includes(this.orderData?.client?.uid)
      ) {
        temp = null
      }

      if (temp !== null) {
        this.allowedTypes.push(temp)
      }

      if (this.allowedTypes.length === 1) {
        this.setOrderType(this.allowedTypes[0].value)
      }
    },
    getServicesList() {
      fetch(`${dashUrl}/ajax/PullCommon.php?action=getServicesListForTrack`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientId: this.orderData.client.uid,
        }),
      })
        .then(result => result.json())
        .then(({ services }) => {
          this.services = services
        })
    },
    setOrderType(orderTypeId) {
      this.rescheduleData.order_type = orderTypeId

      awaitCondition(() => !!this.rescheduleData.date_to_deliver)
        .then(() => {
          const results = this.services[orderTypeId]?.windows.filter(item => item.date === this.rescheduleData.date_to_deliver)

          this.promiseEtaOptions = []
          results.forEach((window, index) => {
            this.promiseEtaOptions.push({
              value: `${formatTime(window.start)}-${formatTime(window.end)}`,
              label: `${formatTime(window.start)}-${formatTime(window.end)}`,
              checked: index === 0,
            })
            if (index === 0) {
              const chosenTime = `${formatTime(window.start)}-${formatTime(window.end)}`
              this.setTimeEta(chosenTime)
              this.rescheduleData.promised_eta = chosenTime
            }
          })
        })
    },
    isNewAddress(oldAddress, newAddress) {
      return oldAddress.address !== newAddress.address
        || oldAddress.apt !== newAddress.apt
        || oldAddress.city !== newAddress.city
        || oldAddress.state !== newAddress.state
        || oldAddress.zip !== newAddress.zip
    },
    async addressChange(toast) {
      if (this.rescheduleData?.address && this.isNewAddress(this.orderData.recipient.address, this.rescheduleData.address)) {
        const url = `${dashUrl}/reschedule/${this.orderData.uid}-${this.orderData.created_at}/`
        const request = this.rescheduleData.address

        request.action = 'recipient_change_address'
        request.oid = this.orderData.uid

        const newAddressResponse = await axios.post(url, request)

        if (newAddressResponse.data.status === 0) {
          toast.error(newAddressResponse.data.text)
          return
        }

        if (newAddressResponse.data.status === 1) {
          toast.success('Address changed successfully')
        }
      }
    },
    async reschedule() {
      this.$store.commit('setLoaderVisible', true)
      this.disabled = true
      const toast = useToast()
      const orderId = this.orderData.uid
      const request = this.rescheduleData
      request.special_instruction = this.orderData.instructions.recipient

      const response = await rescheduleOrder(orderId, request)

      if (response.success) {
        toast.success(response.success)

        await this.addressChange(toast)

        this.handleDelay(() => {
          this.$store.commit('setRescheduleData', {
            date_to_deliver: null,
            pickup_time_min: null,
            pickup_time_max: null,
            order_type: null,
            promised_eta: null,
            special_instruction: this.orderData.instructions.recipient,
          })

          this.$router.push({
            name: 'main_page',
            query: {
              [this.urlParams[0]]: this.urlParams[1],
            },
          })
            .then(() => {
              location.reload()
            })
        })
      } else if (response.error) {
        toast.error(response.error)
        this.handleDelay(() => {
          this.canBeRescheduled = false
        })
      }
      this.disabled = false
      this.$store.commit('setLoaderVisible', false)
    },
    handleDelay(callback) {
      setTimeout(() => {
        callback()
      }, 1000)
    },
    splitTimeRange(timeRange) {
      const times = timeRange.split('-')
      return times.map(time => {
        time = time.trim()
          .toUpperCase()

        const [hours, minutesPart] = time.split(':')
        const [minutes, modifier] = minutesPart.split(/(AM|PM)/)

        let hoursInt = parseInt(hours, 10)
        if (modifier === 'PM' && hoursInt < 12) {
          hoursInt += 12
        } else if (modifier === 'AM' && hoursInt === 12) {
          hoursInt = 0
        }
        return `${hoursInt.toString()
          .padStart(2, '0')}:${minutes}`
      })
    },
    setTimeEta(timeRange) {
      const [timeMin, timeMax] = this.splitTimeRange(timeRange)
      this.rescheduleData.pickup_time_min = timeMin
      this.rescheduleData.pickup_time_max = timeMax
    },
    setTime() {
      this.rescheduleData.promised_eta = null
    },
  },
}
</script>
