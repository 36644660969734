<template>
  <div class="accepted-sign">
    <img src="@/assets/img/accepted-sign.png" alt="">
    <p>{{ $t('call-feedback.accepted-message') }}</p>
  </div>
</template>

<script>
export default {
  name: 'rate-accepted',
}
</script>

<style lang="scss" scoped>
.accepted-sign {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 150px;
    margin-bottom: 25px;
  }

  p {
    text-align: center;
    max-width: 200px;
    line-height: 23px;
  }
}
</style>
