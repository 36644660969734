export default {
  accepted: {
    description: 'Firma aceptada. ¡Gracias por utilizar nuestro servicio de entrega!',
  },
  info: 'Tienes una entrega de',
  title: 'Por favor verifique su información:',
  fields: {
    name: 'Nombre del destinatario:',
    phone: 'Teléfono del destinatario:',
    address: 'Dirección del destinatario:',
    'rx-number': 'Número de Rx:',
  },
  'action-btn': 'Submit',
}
