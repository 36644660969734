<template>
  <div>
    <h2 class="section__title">{{ $t('main-page.delivering.not-confirmed.statuses.driver-out') }}</h2>
  </div>
</template>

<script>

export default {
  name: 'driver-out',
}
</script>
