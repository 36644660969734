export default {
  from: {
    new: 'New',
    'delivery-from': 'Delivery From',
  },
  to: {
    delivered: 'Delivered to',
    deliver: 'Deliver to',
  },
  comment: 'Driver Comment',
}
