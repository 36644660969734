<template>
  <div
    v-if="orderData?.driver && orderData?.client?.customize?.allow_tips === 'yes'"
  >
    <driver-tips> v-if="showTips"></driver-tips>
    <div v-if="!showTips && !!orderData?.paid_tips">
      <div class="paid-tips-content">
        {{ $t('common.tips.tips-component.thanks') }}
      </div>
      <div class="paid-tips-more-button-container">
        <div class="paid-tips-more-button" @click="forceShowTips = true">
          <i class="icon">
            <img svg-inline="" src="@/assets/img/plus.svg" alt="">
          </i>
          <span class="paid-tips-more-button-text">
            {{ $t('common.tips.tips-component.more') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DriverTips from '@/components/_common/TipsComponents/driver-tips.vue'
import { dashUrl } from '@/config'

export default {
  name: 'tips-component',
  components: { DriverTips },
  data() {
    return {
      forceShowTips: false,
      paymentIntent: null,
    }
  },
  computed: {
    ...mapGetters(['orderData']),
    showTips() {
      if (this.forceShowTips) {
        return true
      }

      return !this.orderData?.paid_tips
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    this.paymentIntent = urlParams.get('payment_intent')

    if (this.paymentIntent) {
      fetch(`${dashUrl}/payment/button`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          payment_intent: this.paymentIntent,
          order_id: this.orderData.uid,
        }),
      }).then(result => result.json())
        .then(event => {
          const keys = urlParams.keys()
          let first = null
          for (const key of keys) {
            if (first === null) {
              first = key
            }
          }
          window.location.href = `${window.location.origin}/?${first}=${urlParams.get(first)}`
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.driver-tips-block {
  border: 2px solid #99df3c;
  padding: 15px;
  border-radius: 20px;
  margin-top: 5px;
}

.paid-tips-content {
  color: #111;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 14px;
}

.paid-tips-more-button-container {

  .paid-tips-more-button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .paid-tips-more-button-text {
    color: #87CD2B;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 15.6px */
    text-decoration-line: underline;
    margin-left: 4px;
  }

}
</style>
