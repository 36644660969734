import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/components/MainPage/index.vue'
import ReschedulePage from '@/components/ReschedulePage/index.vue'
import SignatureLink from '@/components/SignatureLink/index.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main_page',
      component: MainPage,
    },
    {
      path: '/reschedule',
      name: 'reschedule_page',
      component: ReschedulePage,
    },
    {
      path: '/sg',
      name: 'signature_link',
      component: SignatureLink,
    },
  ],
})

export default router
