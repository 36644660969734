export default {
  card: {
    title: 'Pay by card',
    inputs: {
      number: {
        tite: 'Card number',
        placeholder: 'Enter card number',
      },
    },
  },
  'driver-tips': {
    title: 'Thank you for<br><span>supporting drivers!</span>',
    description: '100% of your tip goes directly to',
    'confirmation-modal': {
      title: 'Please confirm:',
      description: 'Are you sure you want to send a tip of',
      error: 'The tip amount must be between $2 and $40.',
      actions: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
    },
  },
  'tips-amount': {
    other: 'Other',
  },
  'tips-component': {
    thanks: 'Thank you! Your tip has been received and will surely make the driver\'s day brighter.',
    more: 'Send more tips',
  },
  'tips-old': {
    title: 'Thank you for<br><span>supporting drivers!</span>',
    description: '100% of your tip goes directly to',
    btn: 'Pay Card',
  },
}
