<template>
  <div class="client_info__container delivery__condition delivery__condition--online">
    <p class="icon delivery__condition__warning ">18+</p>
    <h3 class="delivery__condition__title">
      <i class="icon delivery__condition__title__icon">
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.102 3.102a.272.272 0 00-.383.024l-.355.352.361.362.354-.355a.274.274 0 00.023-.383zM8.078 7.765l-.204.565.565-.204c1.06-1.062 2.933-2.93 3.974-3.972l-.361-.361-3.974 3.972z" fill="#0A7EBB"/><path d="M11.08 6.113v6.236a.222.222 0 01-.222.221H3.775a.222.222 0 01-.221-.221V2.92a.222.222 0 01.221-.222h7.083a.222.222 0 01.221.222v1.218l.807-.804V2.71a.912.912 0 00-.909-.913H3.656a.912.912 0 00-.908.913v10.58a.912.912 0 00.908.913h7.321a.912.912 0 00.909-.913V5.306c-.204.202-.606.608-.807.807zm-4.522 7.013h1.588a.221.221 0 010 .443H6.558a.221.221 0 010-.443z" fill="#0A7EBB"/><path d="M5.388 7.333c.537-.381 1.019.098 1.19.57.081.247.245.458.463.6a.82.82 0 00.299-.002l.339-.933a.234.234 0 01.05-.08l2.907-2.906v-1.44H3.997v8.985h6.64V6.556l-1.92 1.918a.249.249 0 01-.081.053l-1.05.38a1.275 1.275 0 01-.749-.01 1.563 1.563 0 01-.66-.806c-.142-.301-.215-.425-.377-.447-.316.001-.417.59-.463.846a.222.222 0 01-.44-.053c.01-.082.106-.802.491-1.104z" fill="#0A7EBB"/></svg>
      </i>
      <span class="delivery__condition__text">{{ $t('common.approve-type.online-signature.label') }}</span>
    </h3>
    <p class="delivery__condition__desc">
      {{ $t('common.approve-type.online-signature.description_1') }}
      {{ $t('common.approve-type.online-signature.description_2') }}
      {{ $t('common.approve-type.online-signature.description_3') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'online-signature',
}
</script>
