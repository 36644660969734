import languageBtn from './languageBtn'
import orderStatuses from './orderStatuses'
import common from './common'
import header from './header'
import callFeedback from './callFeedback'
import footerBtn from './footerBtn'
import mainPage from './mainPage'
import reschedule from './reschedule'
import signature from './signature'

export default {
  'language-btn': languageBtn,
  'order-statuse': orderStatuses,
  common,
  header,
  'call-feedback': callFeedback,
  'ready-for-delivery': 'Su pedido ha sido almacenado de forma segura en la instalación y será entregado ',
  'footer-btn': footerBtn,
  'main-page': mainPage,
  reschedule,
  signature,
}
