<template>
  <div class="wrapper-section pb-10">
    <div class="section mb-0">
      <div class="section__content">
        <reschedule-component
          v-if="!cantBeRescheduled"
          :require-change-address="deliveringState.subState === 'wrong-address'"
          :not-present="true"
        ></reschedule-component>
      </div>
    </div>
  </div>
</template>

<script>
import RescheduleComponent from '@/components/ReschedulePage/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'open-reschedule',
  components: {
    RescheduleComponent,
  },
  computed: {
    ...mapGetters(['orderData', 'deliveringState']),
    cantBeRescheduled() {
      if (this.orderData.reschedule_count > 3) {
        return true
      }

      if (this.orderData.subtype_fridge === 'yes') {
        return true
      }

      if (this.orderData.client.customize.restrict_reschedule === 'yes') {
        return true
      }

      return false
    },
  },
}
</script>
