<template>
  <ul class="feedback__tag_list">
    <li v-for="role in filteredRoles" :key="role">
      <button
        v-for="item in getFeedback(role)"
        :key="item"
        class="feedback__tag_list__item"
        :class="{
          'feedback__tag_list__item--positive': isPositive(role),
          'feedback__tag_list__item--negative': !isPositive(role),
          'feedback__tag_list__item--selected': isSelected(item)
        }"
        style="margin-bottom: 7px"
        @click="addComment(item)"
      >
        {{ item }}
      </button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'feedback-suggestions',
  props: {
    feedbackSent: {
      type: Boolean,
      required: true,
    },
    feedbackData: {
      type: Object,
      required: true,
    },
  },
  emits: ['update-feedback'],
  data() {
    return {
      suggestions: {
        driver: {
          positive: [
            this.$t('main-page.delivered.feedback.suggestions.driver.positive.went-above-and-beyond'),
            this.$t('main-page.delivered.feedback.suggestions.driver.positive.professional-appearance'),
            this.$t('main-page.delivered.feedback.suggestions.driver.positive.courteous-and-respectful'),
            this.$t('main-page.delivered.feedback.suggestions.driver.positive.on-time-delivery'),
          ],
          negative: [
            this.$t('main-page.delivered.feedback.suggestions.driver.negative.unfriendly-driver'),
            this.$t('main-page.delivered.feedback.suggestions.driver.negative.did-not-follow-instructions'),
            this.$t('main-page.delivered.feedback.suggestions.driver.negative.late-delivery'),
            this.$t('main-page.delivered.feedback.suggestions.driver.negative.unprofessional-appearance'),
          ],
        },
        dispatcher: {
          positive: [
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.positive.great-communication'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.positive.patient-and-understanding'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.positive.efficient-coordination'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.positive.quick-response-time'),
          ],
          negative: [
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.negative.impatient-or-rude'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.negative.poor-coordination'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.negative.failed-to-address-concerns'),
            this.$t('main-page.delivered.feedback.suggestions.dispatcher.negative.slow-response-time'),
          ],
        },
        pharmacy: {
          positive: [
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.positive.friendly-staff'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.positive.secure-packaging'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.positive.fast-shipping'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.positive.concise-medication-instructions'),
          ],
          negative: [
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.negative.difficult-to-open'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.negative.incorrect-prescription'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.negative.unfriendly-staff'),
            this.$t('main-page.delivered.feedback.suggestions.pharmacy.negative.wrong-medication'),
          ],
        },
      },
    }
  },
  computed: {
    roles() {
      return ['driver', 'dispatcher', 'pharmacy']
    },
    filteredRoles() {
      return this.roles.filter(role => this.feedbackData[`rate_${role}`] !== 0)
    },
  },
  methods: {
    isPositive(role) {
      return this.feedbackData[`rate_${role}`] >= 4
    },
    getFeedback(role) {
      const type = this.isPositive(role) ? 'positive' : 'negative'
      return this.suggestions[role][type]
    },
    addComment(comment) {
      const trimmedComment = comment.trim()
      let newComment = this.feedbackData.comment || ''
      if (trimmedComment && !newComment.includes(trimmedComment)) {
        newComment = newComment ? `${newComment} ${trimmedComment}.` : `${trimmedComment}.`
      }

      this.$emit('update-feedback', { ...this.feedbackData, comment: newComment })
    },
    isSelected(comment) {
      return this.feedbackData.comment && this.feedbackData.comment.includes(comment.trim())
    },
  },
}
</script>
