export default {
  delivered: {
    'link-feedback-popup': {
      title: 'Gracias por utilizar nuestro servicio y agradecemos sus comentarios.',
      subtitle: 'Por favor, deja una reseña en nuestra página de Google',
      btn: 'De acuerdo',
    },
    info: {
      'payment-delivered-title': 'Entregado',
    },
    feedback: {
      title: 'Tu opinión es importante para nosotros',
      comment: {
        title: 'Por favor deja tus comentarios:',
        placeholder: 'Escribe si te ha gustado todo, tus expectativas e impresiones.',
        'send-btn': 'Enviar comentarios',
        'change-btn': 'Cambiar comentarios',
        'not-send-tooltip': {
          text: 'Contáctame',
          title: 'El servicio de atención al cliente le llamará',
          content: 'Deje sus comentarios y nuestro agente de atención al cliente se comunicará con usted en breve.',
        },
      },
      suggestions: {
        driver: {
          positive: {
            'went-above-and-beyond': 'Fue más allá',
            'professional-appearance': 'Apariencia profesional',
            'courteous-and-respectful': 'Cortés y respetuoso',
            'on-time-delivery': 'Entrega a tiempo',
          },
          negative: {
            'unfriendly-driver': 'Unfriendly Driver',
            'did-not-follow-instructions': 'No siguió las instrucciones',
            'late-delivery': 'Entrega tardía',
            'unprofessional-appearance': 'Apariencia poco profesional',
          },
        },
        dispatcher: {
          positive: {
            'great-communication': 'Gran comunicación',
            'patient-and-understanding': 'Paciente y Comprensión',
            'efficient-coordination': 'Coordinación eficiente',
            'quick-response-time': 'Tiempo de respuesta rápido',
          },
          negative: {
            'impatient-or-rude': 'Impaciente o grosero',
            'poor-coordination': 'Mala coordinación',
            'failed-to-address-concerns': 'No se abordaron las preocupaciones',
            'slow-response-time': 'Tiempo de respuesta lento',
          },
        },
        pharmacy: {
          positive: {
            'friendly-staff': 'Personal amable',
            'secure-packaging': 'Embalaje seguro',
            'fast-shipping': 'Envíos rápidos/recargas',
            'concise-medication-instructions': 'Instrucciones breves sobre la medicación',
          },
          negative: {
            'difficult-to-open': 'Difícil de abrir',
            'incorrect-prescription': 'Receta incorrecta',
            'unfriendly-staff': 'Personal antipático',
            'wrong-medication': 'Wrong medication',
          },
        },
      },
    },
  },
  delivering: {
    confirmed: {
      'stops-away': 'se detiene',
      'your-stop-is-next': 'Tu parada es la siguiente',
      'special-instruction': {
        title: 'Instrucción especial de farmacia:',
        'new-instruction-input': {
          title: 'Instrucciones especiales para el destinatario',
          placeholder: 'Agregar instrucción especial',
        },
        'new-instruction-btn-save': 'Ahorrar',
      },
      'arriving-data': {
        today: {
          'label-2': 'Arriving todayLlegando hoy',
          label: 'Tiempo estimado de entrega',
        },
        next: {
          label: 'El conductor llegará',
          time: 'Pocos minutos',
        },
        'few-minutes': {
          'label-2': 'El conductor llegará',
        },
      },
      qr: {
        info: 'Mostrar el código QR al conductor',
        description: 'Para recibir su pedido, muestre el código QR al conductor o llame al código',
        help: 'Un copago es una cantidad específica en dólares que usted debe pagar por servicios de atención médica o recetas cubiertos, según lo define su plan de seguro.',
      },
    },
    'confirmed-no-route': {
      title: 'Confirmaste la entrega',
      subtitle: 'Espere información',
    },
    'not-confirmed': {
      form: {
        'empty-title': 'Asegúrese de dejar una firma',
        'pharmacy-instruction-title': 'Instrucción especial de farmacia:',
        'recipient-instruction': {
          title: 'Instrucciones especiales para el destinatario:',
          placeholder: 'Agregar instrucción especial',
          'update-btn': 'Actualizar',
        },
        sign: {
          description: 'El conductor intentará realizar la entrega en persona. Si no hay nadie en casa, dejará el paquete junto a la puerta y tomará una foto.',
          confirm: {
            btn: 'Confirmar entrega',
            title: 'Estas seguro?',
            text: 'Confirmo que la información que he proporcionado es correcta y precisa',
          },
        },
      },
      progress: {
        attempts: 'Intento de entrega:',
        created: 'Orden <br> creada',
      },
      transit: 'Tránsito',
      'out-for-delivery': 'En salida para <br> entrega',
      delivered: 'Entregado',
      signature: {
        title: 'Firme a continuación para permitir la entrega de paquetes sin firma:',
        clear: 'Claro',
      },
      statuses: {
        'at-pharmacy': 'Su pedido está en la farmacia y será entregado',
        'driver-out': 'Confirma tu entrega',
        reschaduled: 'Entrega reprogramada para',
        'pending-back-to-pharmacy': 'Su pedido está pendiente de devolución a la farmacia',
        'pickup-occured': 'Su pedido ha sido recogido en la farmacia y será entregado',
      },
    },
    rescaduled: {
      attempted: 'Intento de',
    },
  },
  investigation: {
    link_start: 'Por favor contáctenos en',
    link_end: 'para programar una entrega',
    instructions: 'Instrucción especial de farmacia:',
  },
  rescadule: {
    subtitle: 'Por favor, seleccione una nueva fecha de entrega',
  },
  rejected: {
    'back-to-pharmacy': {
      rejected: 'El pedido ha sido cancelado por la farmacia.',
      'pending-back-to-pharmacy': 'Your order has been canceled and pending return back to the pharmacy',
      'on-its-way-to-pharmacy': 'Su pedido ha sido cancelado y se confirmó la devolución a la farmacia',
      default: 'Su pedido ha sido cancelado y enviado de vuelta a la farmacia.',
      'confirmed-back-to-pharmacy': 'Su pedido ha sido cancelado, esperando confirmación de devolución de la farmacia',
      'back-to-pharmacy': 'Su pedido ha sido cancelado y enviado de vuelta a la farmacia',
    },
  },
  returned: {
    'back-to-pharmacy': {
      title: 'Su pedido está pendiente de devolución a la farmacia',
    },
  },
}
