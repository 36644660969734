<template>
  <div>
    <delivered-order v-if="deliveredConditions"></delivered-order>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveredOrder from './Delivered.vue'

export default {
  name: 'text-by-status',
  components: { DeliveredOrder },
  computed: {
    ...mapGetters(['orderData']),
    deliveredConditions() {
      return this.orderData.status_slug === 'delivered'
    },
  },
}
</script>
